<template>
    <div class="memberInfoCheckCoupon">
        <div class="left-box">
            <div class="title">会员信息</div>
            <div class="tr-li card-tr">
                <div class="lable-txt">卡号：</div>
                <div class="from-input">
                    <div class="card-no"><input-pattern keyBoard pattern="code" placeholder="请输入卡号或读卡" v-model="cardNo" @keyup.enter="selectReadCard()" :maxlength="20"/><i class="iconfont icon-sousuo" @click="selectReadCard()"></i></div>  
                    <div class="read-card" @click="readCard()">读卡</div>    
                </div>
            </div>
            <div class="info-box">
                <div class="title">客户资料信息</div>
                <div class="tr-li">
                    <div class="lable-txt">会员等级：</div>
                    <div class="from-input"><input type="text" :value="memberInfo?.Bestech_Card_KindName"  readonly/></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">客户姓名：</div>
                    <div class="from-input"><input type="text"  :value="memberInfo?.MemberName"  readonly/></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">手机号：</div>
                    <div class="from-input"><input type="text" :value="memberInfo?.Mobile" maxlength="10" readonly/></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">生日：</div>
                    <div class="from-input"><input type="text" :value="memberInfo?.Birthday" readonly/></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">性别：</div>
                    <div class="from-input"><input type="text" :value="{0:'男',1:'女'}[memberInfo?.Gender]" readonly/></div>
                </div>
                <div class="title">卡资产信息</div>
                <div class="tr-li">
                    <div class="lable-txt">余额：</div>
                    <div class="from-input"><input class="text-right" type="text" :value="memberInfo?.Card_Money" readonly/>
                        <p class="describe" :style="memberInfo?'':'visibility: hidden'">其中赠送金额{{memberInfo?.Gift_Money}}元</p>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">积分：</div>
                    <div class="from-input"><input class="text-right" type="text" :value="memberInfo?.Card_PilePoint"  readonly/>
                        <p class="describe"></p>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">券：</div>
                    <div class="from-input ticket" @click="ticketClick()"><input class="text-right" :value="(memberInfo?.Coupons?.length||'0')+'张'" type="text" readonly/><i class="iconfont">></i>
                        <p class="describe"></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="right-box">
            <div class="title">券列表</div>
            <div class="coupon-box">
                <div class="coupon-scroll"  v-scroll-anime="{up:'pay-up',down:'pay-down',liClass:'.item-tr',marginH:8}">
                    <div class="item-tr" v-for="item in couponsList" :key="item" >
                        <div class="coupons-box">
                            <div class="coupons-name">
                                <div class="name nowrap2">{{item.Coupon_Name}}</div>
                                <div class="describe nowrap"  v-show="item.Is_Have_Validity==true" >
                                    {{new Date(item.Begin_Date).Format("yyyy-MM-dd")}}至{{new Date(item.End_Date).Format("yyyy-MM-dd")}}止
                                </div>
                            </div>
                            <div class="coupons-num">{{item.number}}<span class="unit">张</span></div>
                        </div> 
                        <div class="writeOff-box">
                            核销张数:  
                            <input-pattern class="writeOff-input" keyBoard :ref="(e)=>verifyInput[index]=e" v-model="item.Verify_Num" pattern="number" :dplaces="0"/>
                        </div>
                    </div> 
                </div>
                <div class="item-operation">
                    <div class="float-right">
                        <div class="bnt-fangxiang" ref="pay-up"><i class="iconfont icon-fangxiang-up"></i></div>
                        <div class="bnt-fangxiang" ref="pay-down"><i class="iconfont icon-fangxiang-down"></i></div>
                    </div>
                </div>
            </div>
            <div class="footer-box">
                <button class="btn" @click="back()">返回</button>
                <button class="btn selected" @click="confirm()">确定</button>
            </div>
        </div>
        <!-- 券列表 -->
        <modal-load :isShow="ticketShow">
            <ticket-model :isShow="ticketShow" @closeModel="ticketShow=false" :Coupons="memberInfo?.Coupons"></ticket-model>
        </modal-load> 
        <!-- 选择卡信息 -->
        <modal-load :isShow="cardMemberShow">
            <select-card-member-info :isShow="cardMemberShow" @closeModel="cardMemberShow=false" :data="cardMemberList" @confirm="selectCardInfo"></select-card-member-info>
        </modal-load>
    </div>
</template>

<script>
import { ticketModel,selectCardMemberInfo } from '../model'
/**手动核销券 */
export default {
    name:'memberInfoCheckCoupon',
    components:{ticketModel,selectCardMemberInfo},
    data(){
        return {
            /**卡号 */
            cardNo:'',
            /**卡id */
            card_AutoID:'',
             /**会员信息 */
            memberInfo:undefined,
            /**券列表 */
            ticketShow:false,
            /**选择卡信息 */
            cardMemberShow:false,
            /**多个卡信息 */
            cardMemberList:[],
            //核销输入框
            verifyInput:[],
            couponsList:[],
        }
    },
    watch:{
        cardNo(){
            this.memberInfo=null;
        },
        memberInfo(){
            /**合并券列表 */
            let coupons=[];
            this.memberInfo?.Coupons?.filter(it=>it.Use_Status==0)?.forEach(it => {
                let item=coupons.find(item=>it.Tk_AutoID==item.Tk_AutoID && it.Coupon_Name==item.Coupon_Name && it.Begin_Date==item.Begin_Date && it.End_Date==item.End_Date);
                if(item){
                    item.number++;
                }else{
                    coupons.push(Object.assign({number:1,Verify_Num:''},it));
                }

            });
            this.couponsList=coupons;
        }
    },
    methods:{
        /**退出 */
        back(){
            this.$emit("close");
        },
        /*刷新当前页面 */
        reload() {
            this.$emit("reload");
        },
        ticketClick(){
            if(this.memberInfo){
                this.ticketShow = true;
            }
        },
        /**点击查询图标读卡   m1卡 Card_AutoID 卡id,Card_SN 内卡号 */
        selectReadCard(Card_AutoID,Card_SN){
            if(!this.cardNo && !Card_SN){
                this.$message.warning('请填写会员卡号')
                return
            }
            const loading = this.$loading({
                text: "读取会员信息中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetMemberInfo",{
                User_ID:this.userInfo?.User_ID,
                DynamicNo:this.cardNo,
                Card_AutoID:Card_AutoID,
                Card_SN:Card_SN
            }).then((data)=>{
                loading.close();
                console.log('会员信息:',data)
                if(data.ResponseHeader.ResultCode==0){
                    if(data.ResponseBody.length>1){
                        this.cardMemberList=data.ResponseBody;
                        this.cardMemberShow=true;
                    }else{
                        this.selectCardInfo(data.ResponseBody[0])
                    }
                }else{
                    this.memberInfo=null,
                    this.$message.error("会员信息读取失败："+data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.memberInfo=null,
                this.$message.error('会员信息读取失败：'+e);
                console.log('会员信息读取失败：'+e);
            })
        },
        /**点击 读卡 */
        readCard(){
            this.$webBrowser.redCardM1().then((d)=>{
                if(d){
                    if(d.state==0){
                        this.card_AutoID=d.data.cardID;
                        this.cardNo=d.data.cardNo;
                        this.selectReadCard(d.data.cardID,d.data.snr);
                    }else{
                        this.$message.error('卡信息读取失败：'+d.message);
                    }
                }
            })
        },
        /**多个卡信息时选择一个 */
        selectCardInfo(data){
            this.cardMemberShow=false;
            this.cardNo=data.Card_No;
            this.$nextTick(()=>{
                this.memberInfo = data;
                this.card_AutoID = this.memberInfo?.Bestech_Card_AutoID;
            })
        },
        confirm(){
            if(!this.memberInfo){
                this.$message.warning('请确认获取到卡号信息');
                return;
            }
            for(let i=0;i<this.couponsList.length;i++){
                let item=this.couponsList[i];
                if(item.Verify_Num>item.number){
                    this.verifyInput[i]?.focus()
                    this.$message.warning('核销数量不能大于卡券数量');
                    return;
                }
            }
            let list=this.couponsList.filter(it=>it.Verify_Num>0);
            if(list.length==0){
                this.$message.warning('请在需要核销的卡券上输入核销数量');
                return
            }
            
            
            let userInfo=this.$auth.getUserInfo();
            let param={
                Card_AutoID:this.memberInfo.Bestech_Card_AutoID, //卡主键
                Pos_Czy:userInfo?.Login_Name,//操作员
                BatchVerfiyInfos:list.map(it=>{
                    return {
                        TK_AutoID:it.Tk_AutoID,
                        Coupon_Name:it.Coupon_Name,
                        Begin_Date:it.Begin_Date,
                        End_Date:it.End_Date,
                        Verify_Num:it.Verify_Num
                    };
                })
            }
            console.log(param)
            const loading = this.$loading({
                text: "卡券核销中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.MemberCouponBatchVerify",param).then((data)=>{
                loading.close();
                console.log('卡券核销返回信息:',data)
                if(data.ResponseHeader.ResultCode==0){
                    this.$message.success("卡券核销完成");
                    this.reload()
                }else{
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('卡券核销失败：'+e.message);
                console.log('卡券核销：',e);
            })
        }
    }
}
</script>

<style lang="scss">
@import "./memberInfoCheckCoupon.scss";
</style>